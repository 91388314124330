import React from "react";
import "./Home.css";

const AboutUs = () => {
  return (
    <div className="w-full h-auto  lg:h-[100vh] flex justify-center items-center flex-wrap">
      <div className="w-[600px] h-[600px] bg_aboutUs rounded-lg mr-1 mt-2 mb-6"></div>
      <div className="w-[600px] h-auto lg:h-[600px]  ">
        <div className="w-full h-[10%] bg-white flex items-center">
          <p className="ml-[15px] text-6xl">
            About <span className="border_AboutUs ">us?</span>
          </p>
        </div>
        <div className="flex text-left p-[20px]">
          <p className="text-2xl ">
            Since we opened our doors, we have collaborated with various
            clients. Good service begins and ends with expert and friendly
            professionals, that is why our process of hiring is rigorous to
            achieve it. We conclude projects efficiently and on time. We always
            go beyond our boundaries to build lasting relationships with
            clients.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
